import { useMemo } from 'react';
import { useMatches } from '@remix-run/react';

function useMatchesData<T>(id: string): T {
    const matchingRoutes = useMatches();
    const route = useMemo(() => matchingRoutes.find((route) => route.id === id), [matchingRoutes, id]);
    return route?.data as T;
}

export default useMatchesData;
