import useMatchesData from '~/utils/hooks/useMatchesData';
import { CurrentUserTypes } from '~/types/app/user';
import { RootLoaderData } from '~/root';
import { transformUser } from './transformers/backend2app';
import { isUserAuthenticated } from './auth';
import { getCart, getPublicCart } from './apis/shop';
import { buildErrorMessage } from './error';
import { getAdditionalUserData, getUser } from './apis/user';
import { getUsersSubscriptions } from './apis/inventory';
import { ApiResponse } from '~/types/api';
import { SubscriptionPlanProfileTypes } from '~/types/app/inventory';

function isCurrentUser(currentUser: any): currentUser is CurrentUserTypes {
    return currentUser && typeof currentUser === 'object';
}

export function useOptionalUser(): CurrentUserTypes | undefined {
    const data = useMatchesData<RootLoaderData>('root');
    if (!data || !isCurrentUser(data.currentUser)) {
        return undefined;
    }
    return data.currentUser;
}

export function useCurrentUser(): CurrentUserTypes {
    const maybeUser = useOptionalUser();
    if (!maybeUser) {
        throw new Error(
            'No user found in root loader, but user is required by CurrentUserTypes. If user is optional, try useOptionalUser instead.',
        );
    }
    return maybeUser;
}

export const fetchUser = async (request: Request): Promise<CurrentUserTypes> => {
    let currentUser: CurrentUserTypes = {
        signedIn: false,
        user: transformUser(),
        cart: [],
        userHasActiveSubscriptions: false,
        userHasAPlusSubscription: false,
        userHasSubscriptionWithAutomaticRenewal: false,
        subscriptionExpiresWithinTwoWeeks: false,
        additionalUserData: {},
    };

    try {
        if (!isUserAuthenticated(request)) {
            const publicCartResponse = await getPublicCart(request);

            if (publicCartResponse.success) {
                currentUser = { ...currentUser, cart: publicCartResponse.data || [] };
            } else {
                throw new Response(buildErrorMessage(publicCartResponse, 'Failed to fetch public cart'), {
                    status: publicCartResponse.status,
                });
            }
        }

        if (isUserAuthenticated(request)) {
            const [user, usersSubscriptions, additionalUserData, userCartResponse] = await Promise.all([
                getUser(request),
                getUsersSubscriptions(request),
                getAdditionalUserData(request),
                getCart(request),
            ]);

            if (user.success) {
                currentUser = { ...currentUser, signedIn: true, user: user.data };
            } else {
                throw new Response(buildErrorMessage(user, "Couldn't fetch user in root loader"), {
                    status: user.status,
                });
            }

            if (usersSubscriptions.success) {
                currentUser = {
                    ...currentUser,
                    ...getUsersSubscriptionState(usersSubscriptions),
                };
            } else {
                throw new Response(
                    buildErrorMessage(usersSubscriptions, "Couldn't fetch user subscriptions in root loader"),
                    {
                        status: usersSubscriptions.status,
                    },
                );
            }

            if (additionalUserData.success) {
                currentUser = { ...currentUser, additionalUserData: additionalUserData.data };
            }

            if (userCartResponse.success) {
                currentUser = { ...currentUser, cart: userCartResponse.data || [] };
            } else {
                throw new Response(buildErrorMessage(userCartResponse, "Couldn't fetch user cart in root loader"), {
                    status: userCartResponse.status,
                });
            }
        }
    } catch (e: any) {
        return { ...currentUser, error: e.message };
    }

    return currentUser;
};

const getUsersSubscriptionState = (
    usersSubscriptions: ApiResponse<{
        items: SubscriptionPlanProfileTypes[];
    }>,
): Record<string, boolean> => {
    let userHasActiveSubscriptions = false;
    let userHasAPlusSubscription = false;
    let userHasSubscriptionWithAutomaticRenewal = false;
    let subscriptionExpiresWithinTwoWeeks = false;

    userHasActiveSubscriptions = usersSubscriptions.data.items.some(
        (subscription) => subscription.expires === null || Date.parse(subscription.expires) > Date.now(),
    );

    userHasSubscriptionWithAutomaticRenewal = usersSubscriptions.data.items.some(
        (subscription) => !subscription.expires,
    );

    userHasAPlusSubscription = usersSubscriptions.data.items.some(
        (subscription) =>
            subscription.ruleset_id === 'aplus' &&
            (subscription.expires === null || Date.parse(subscription.expires) > Date.now()),
    );

    if (userHasAPlusSubscription) {
        const activeAPlusSubscriptions = usersSubscriptions.data.items.filter(
            (subscription) =>
                subscription.ruleset_id === 'aplus' &&
                (subscription.expires === null || Date.parse(subscription.expires) > Date.now()),
        );

        subscriptionExpiresWithinTwoWeeks = activeAPlusSubscriptions.some(
            (subscription) =>
                subscription.expires &&
                Date.parse(subscription.expires) - Date.now() > 0 &&
                Date.parse(subscription.expires) - Date.now() < 1000 * 60 * 60 * 24 * 14,
        );
    }

    return {
        userHasActiveSubscriptions,
        userHasAPlusSubscription,
        userHasSubscriptionWithAutomaticRenewal,
        subscriptionExpiresWithinTwoWeeks,
    };
};
